import React from "react";
import LogoImportantes from '../../../../../img/logos/LogoImportantes.png';
import Hospital from '../../../../../img/home/Hospital.jpg';
import Prefeitura from '../../../../../img/home/Prefeitura.jpg';
import Delegacia from '../../../../../img/home/delegacia.jpg';

const Importantes = () => {
    return (
        <div className="background-container-fluid background-home-cards-fundo">
            <div className="d-flex align-items-center justify-content-center">
                <div className="link-decoration">
                    <img src={LogoImportantes} alt="Importantes" className="logos"/>
                    <h3 className="titulo ms-3 mb-0 text-black">Importantes</h3>
                </div>
            </div>
            <div className="cards-importantes">
                <div className="cards-importantes-col">
                    <div className="card h-100 border-0">
                        <img src={Hospital} className="card-img-top img-fluid"
                             alt="Imagem Ilustrativa Do Hospital De Gaspar"
                             style={{height: '200px', objectFit: 'cover'}}/>
                        <div className="card-body background-cards-home">
                            <h5 className="card-title fs-2 text-warning fw-bold">Hospital</h5>
                            <p className="card-text fs-6 text-light fw-medium">
                                Hospital Nossa Senhora do Perpétuo Socorro
                                <br/>
                                Telefone: (47) 3144-8200
                            </p>
                        </div>
                    </div>
                </div>
                <div className="cards-importantes-col">
                    <div className="card h-100 border-0">
                        <img src={Prefeitura} className="card-img-top img-fluid"
                             alt="Imagem Ilustrativa Da Prefeitura de Gaspar"
                             style={{height: '200px', objectFit: 'cover'}}/>
                        <div className="card-body background-cards-home">
                            <h5 className="card-title fs-2 text-warning fw-bold">Prefeitura</h5>
                            <p className="card-text fs-6 text-light fw-medium">
                                Prefeitura Municipal de Gaspar
                                <br/>
                                Telefone: (47) 3091-2000
                            </p>
                        </div>
                    </div>
                </div>
                <div className="cards-importantes-col">
                    <div className="card h-100 border-0">
                        <img src={Delegacia} className="card-img-top img-fluid"
                             alt="Imagem Ilustrativa Da Delegacia de Polícia Civil de Gaspar"
                             style={{height: '200px', objectFit: 'cover'}}/>
                        <div className="card-body background-cards-home">
                            <h5 className="card-title fs-2 text-warning fw-bold">Delegacia de Polícia</h5>
                            <p className="card-text fs-6 text-light fw-medium">
                                Polícia Civíl
                                <br/>
                                Telefone: (47) 3332-1000
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Importantes;
