import React from "react";
import GasparCG from '../../../../../img/home/GasparHomePage.jpg'
import GasparFlag from '../../../../../img/flags/Bandeira-gaspar.png'
import LogoTurismo from '../../../../../img/logos/LogoTurismoUrbano.png'
import '../style.css';
import {Link} from "react-router-dom";

const HomePageIntro = () => {
    return (
        <div className="homepage">
            <div className="background-image">
                <img className="img-fluid" src={GasparCG} alt="Imagem De Gaspar No Fundo Da Página"/>
            </div>
            <div className="content">
                <div className="flag-text">
                    <img className="rounded flag-image" src={GasparFlag} alt="Bandeira De Gaspar"/>
                </div>
                <div className="logo-turismo">
                    <img src={LogoTurismo} alt="Logo Do Turismo Urbano"/>
                </div>
                <p className="text-uppercase fw-bold fst-italic text-light text-center title-text">
                    GASPAR/SC
                </p>
            </div>
            <div className="button-container text-center">
                <Link to="/PontosTuristicos">
                    <button className="btn-initial-page">Visite Já</button>
                </Link>
            </div>
        </div>
    );
}

export default HomePageIntro;
