import React, {useEffect, useState} from 'react';
import {GoogleMap, Marker, useLoadScript, DirectionsRenderer} from '@react-google-maps/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import {Local} from './CardList';
import "./Cards.css"
import axios from "axios";
import {Rating} from '@mui/material';

const mapContainerStyle = {
    height: "200px",
    width: "100%",
};

interface CardProps {
    local: Local;
}

const Card: React.FC<CardProps> = ({local}) => {
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: 'AIzaSyCpKt4zmu0y3yg_tvkjxu5FQUVxz-wZq3I',
        libraries: ['places'],
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState<Local | null>(null);
    const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);
    const [origin, setOrigin] = useState<{ lat: number, lng: number } | null>(null);
    const [mapKey, setMapKey] = useState<number>(0);
    const [rating, setRating] = useState<number | null>(null);

    useEffect(() => {
        if (modalOpen) {
            setMapKey(prevKey => prevKey + 1);
        }
    }, [modalOpen]);

    const fetchGoogleRating = async (placeId: string) => {
        try {
            const response = await axios.get(`https://api-gaspar.turismourbano.com.br/proxy/api/place/details/json`, {
                params: {
                    place_id: placeId,
                    fields: 'rating',
                    key: 'AIzaSyCpKt4zmu0y3yg_tvkjxu5FQUVxz-wZq3I',
                },
            });

            const data = response.data;

            if (data.result && typeof data.result.rating !== 'undefined') {
                return data.result.rating;
            } else {
                console.error('Rating não encontrado na resposta da API');
                return null;
            }
        } catch (error) {
            console.error('Erro ao buscar avaliação:', error);
            return null;
        }
    };

    const openGoogleReviews = (name: string) => {
        const url = `https://www.google.com/maps/place/?q=${name}%20Gaspar%20SC`;
        window.open(url, '_blank');
    };

    const openModal = async (local: Local) => {
        setSelectedCard(local);
        setModalOpen(true);
        setDirections(null);
        setOrigin(null);
        setRating(null);

        try {
            const fetchedRating = await fetchGoogleRating(local.placeId);
            if (fetchedRating !== null) {
                setRating(fetchedRating);
            } else {
                console.warn('Não foi possível obter a avaliação do local.');
            }
        } catch (error) {
            console.error('Erro ao buscar avaliação:', error);
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const originLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                setOrigin(originLocation);

                const destination = {
                    lat: local.latitude,
                    lng: local.longitude
                };
                const directionsService = new google.maps.DirectionsService();
                directionsService.route(
                    {
                        origin: originLocation,
                        destination: destination,
                        travelMode: google.maps.TravelMode.DRIVING
                    },
                    (result, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            setDirections(result);
                        } else {
                            console.error(`error fetching directions ${result}`);
                        }
                    }
                );
            });
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        setDirections(null);
        setOrigin(null);
    };

    const mapOptions: google.maps.MapOptions = {
        disableDefaultUI: true,
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        gestureHandling: 'greedy'
    };

    return (
        <>
            <div className="card col-md-4 col-12 mb-3 mt-3">
                <div id={`carousel-${local.id}`} className="carousel slide">
                    <div className="carousel-indicators">
                        {local.imageUrls.map((_, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target={`#carousel-${local.id}`}
                                data-bs-slide-to={index}
                                className={index === 0 ? "active" : ""}
                                aria-current={index === 0 ? "true" : undefined}
                                aria-label={`Slide ${index + 1}`}
                            ></button>
                        ))}
                    </div>
                    <div className="carousel-inner">
                        {local.imageUrls.map((url, index) => (
                            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                                <img src={`https://api-gaspar.turismourbano.com.br/api/places/image/${url}` || '/path/to/default/image.jpg'} className="d-block w-100"
                                     alt={`Slide com imagens do local, slide de número ${index + 1}`}/>
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target={`#carousel-${local.id}`}
                            data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target={`#carousel-${local.id}`}
                            data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
                <div className="card-body">
                    <h5 className="card-title">{local.name}</h5>
                    <p className="card-text" style={{maxHeight: '100px', overflowY: 'auto'}}>
                        {local.description.length > 100 ? `${local.description.substring(0, 100)}...` : local.description}
                    </p>
                </div>
                <button className="bg-warning border-0 p-3 w-100" onClick={() => openModal(local)}>Visualizar</button>
            </div>
            {selectedCard && (
                <div
                    className={`modal fade ${modalOpen ? "show d-block" : ""}`}
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden={!modalOpen}
                >
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fw-bold" id="exampleModalCenterTitle">
                                    {selectedCard.name}
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={closeModal}
                                    aria-label="Close"
                                    data-bs-dismiss="modal"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="row g-3">
                                    <div className="col-md-6 col-12">
                                        <div id={`modal-carousel-${selectedCard.id}`} className="carousel slide">
                                            <div className="carousel-indicators">
                                                {selectedCard.imageUrls.map((_, index) => (
                                                    <button
                                                        key={index}
                                                        type="button"
                                                        data-bs-target={`#modal-carousel-${selectedCard.id}`}
                                                        data-bs-slide-to={index}
                                                        className={index === 0 ? "active" : ""}
                                                        aria-current={index === 0 ? "true" : undefined}
                                                        aria-label={`Slide ${index + 1}`}
                                                    ></button>
                                                ))}
                                            </div>
                                            <div className="carousel-inner">
                                                {selectedCard.imageUrls.map((url, index) => (
                                                    <div
                                                        key={index}
                                                        className={`carousel-item ${index === 0 ? "active" : ""}`}
                                                    >
                                                        <img
                                                            src={`https://api-gaspar.turismourbano.com.br/api/places/image/${url}` || "/path/to/default/image.jpg"}
                                                            className="d-block w-100"
                                                            alt={`Slide ${index + 1}`}
                                                            style={{maxHeight: '300px', objectFit: 'cover'}}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                            <button
                                                className="carousel-control-prev"
                                                type="button"
                                                data-bs-target={`#modal-carousel-${selectedCard.id}`}
                                                data-bs-slide="prev"
                                            >
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Previous</span>
                                            </button>
                                            <button
                                                className="carousel-control-next"
                                                type="button"
                                                data-bs-target={`#modal-carousel-${selectedCard.id}`}
                                                data-bs-slide="next"
                                            >
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Next</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <p className="text-break fs-6">{selectedCard.description}</p>
                                        {rating !== null && (
                                            <div
                                                style={{cursor: "pointer"}}
                                                onClick={() => selectedCard?.name && openGoogleReviews(selectedCard.name)}>
                                                <Rating name="half-rating-read" size="large" value={rating}
                                                        precision={0.1}
                                                        readOnly/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        {isLoaded && (
                                            <GoogleMap
                                                key={mapKey}
                                                mapContainerStyle={{
                                                    width: "100%",
                                                    height: "300px",
                                                }}
                                                center={{
                                                    lat: selectedCard.latitude,
                                                    lng: selectedCard.longitude,
                                                }}
                                                zoom={15}
                                                options={mapOptions}
                                            >
                                                {origin && <Marker position={origin} label="Você"/>}
                                                <Marker
                                                    position={{
                                                        lat: selectedCard.latitude,
                                                        lng: selectedCard.longitude,
                                                    }}
                                                    label={selectedCard.name}
                                                />
                                                {directions && (
                                                    <DirectionsRenderer directions={directions}/>
                                                )}
                                            </GoogleMap>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Card;
