import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import {getPlaces, updatePlace, deletePlace, getCategories} from '../api/api';
import {GoogleMap, useLoadScript, Marker, Autocomplete} from "@react-google-maps/api";
import './placecomponent.css';
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const mapContainerStyle = {
    height: '50vh',
    width: "100%",
};

const PlaceComponent: React.FC = () => {
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: 'AIzaSyCpKt4zmu0y3yg_tvkjxu5FQUVxz-wZq3I',
        libraries: ['places'],
    });

    const [places, setPlaces] = useState<any[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentPlace, setCurrentPlace] = useState<any>({
        id: 0,
        name: '',
        categoryId: 0,
        description: '',
        latitude: 0,
        longitude: 0,
        isChecked: false,
        value: 0
    });
    const [selectedLocation, setSelectedLocation] = useState<google.maps.LatLng | null>(null);
    const [newPlaceName, setNewPlaceName] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);
    const [newDescription, setNewDescription] = useState('');
    const [newLatitude, setNewLatitude] = useState<number>(0);
    const [newLongitude, setNewLongitude] = useState<number>(0);
    const [newValue, setNewValue] = useState<number>(0);
    const [newIsChecked, setNewIsChecked] = useState<boolean>(false);
    const [imageFiles, setImageFiles] = useState<File[]>([]);
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [carouselKey, setCarouselKey] = useState(0);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [newPlaceAddress, setNewPlaceAddress] = useState('');
    const [formattedValue, setFormattedValue] = useState<string>('R$0,00');
    const [center, setCenter] = useState({
        lat: currentPlace.latitude,
        lng: currentPlace.longitude,
    });
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [suggestions, setSuggestions] = useState<google.maps.places.PlaceResult[]>([]);
    const [mapCenter, setMapCenter] = useState(center);
    const [charCount, setCharCount] = useState(0);
    const charLimit = 410;
    const suggestionsRef = useRef<HTMLUListElement>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);
    const [placeToDelete, setPlaceToDelete] = useState<number | null>(null);

    useEffect(() => {
        if (isLoaded && inputRef.current) {
            autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current!, {
                bounds: {
                    north: -26.877904,
                    south: -27.028572,
                    east: -49.105120,
                    west: -48.880565
                },
                strictBounds: true,
                types: ['geocode'],
                componentRestrictions: {country: 'br'},
            });

            const observer = new MutationObserver(() => {
                const autocompleteElement = document.querySelector('.pac-container');
                if (autocompleteElement) {
                    (autocompleteElement as HTMLElement).style.display = 'none';
                }
            });

            observer.observe(document.body, { childList: true, subtree: true });

            autocompleteRef.current.addListener('place_changed', () => {
                const place = autocompleteRef.current!.getPlace();
                if (place.geometry) {
                    const lat = place.geometry.location?.lat() || 0;
                    const lng = place.geometry.location?.lng() || 0;

                    setNewLatitude(lat);
                    setNewLongitude(lng);

                    setMapCenter({ lat, lng });
                    setSelectedLocation(new google.maps.LatLng(lat, lng));
                }
            });

            return () => observer.disconnect();
        }

        const fetchData = async () => {
            const placesData = await getPlaces();
            const categoriesData = await getCategories();
            setPlaces(placesData);
            setCategories(categoriesData);
        };

        fetchData();

        const input = inputRef.current;
        const handleInput = () => handleSearchAddress();

        if (input) {
            input.addEventListener('input', handleInput);
        }

        return () => {
            if (input) {
                input.removeEventListener('input', handleInput);
            }
        }

    }, [isLoaded]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
                setSuggestions([]);
                if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
                    setNewPlaceAddress('');
                    if (inputRef.current) {
                        inputRef.current.value = '';
                    }
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const openModal = (place: any) => {
        setCurrentPlace(place);
        setNewPlaceName(place.name);
        setSelectedCategoryId(place.categoryId);
        setNewDescription(place.description);
        setNewLatitude(place.latitude);
        setNewLongitude(place.longitude);
        setNewIsChecked(place.isChecked);
        setImageFiles([]);
        setImageUrls(place.imageUrls || []);
        setIsEditing(true);
        setModalIsOpen(true);
        setNewValue(place.value);
        setFormattedValue(formatCurrencyToString(place.value));
        setMapCenter({ lat: place.latitude, lng: place.longitude });
        setSelectedLocation(new google.maps.LatLng(place.latitude, place.longitude));

    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSuggestions([]);
        setNewPlaceAddress('');
    };
    const openDeleteConfirmationModal = (id: number) => {
        setPlaceToDelete(id);
        setDeleteConfirmationIsOpen(true);
    };
    const confirmDeletePlace = async () => {
        if (placeToDelete !== null) {
            await handleDelete(placeToDelete);
            closeDeleteConfirmationModal();
        }
    };
    const handleDeleteClick = (id: number) => {
        openDeleteConfirmationModal(id);
    };


    const closeDeleteConfirmationModal = () => {
        setPlaceToDelete(null);
        setDeleteConfirmationIsOpen(false);
    };


    const handleMapClick = async (event: google.maps.MapMouseEvent) => {
        const latLng = event.latLng;

        if (latLng) {
            setSelectedLocation(latLng);
            setNewLatitude(latLng.lat());
            setNewLongitude(latLng.lng());
        }
    }

    const handleSearchAddress = async () => {
        if (!newPlaceAddress) return;

        if (isLoaded && inputRef.current) {
            const service = new google.maps.places.PlacesService(document.createElement('div'));
            const query = inputRef.current.value;

            const request = {
                location: new google.maps.LatLng(-26.9302, -48.9572),
                radius: 10000,
                keyword: query,
            };

            service.nearbySearch(request, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    setSuggestions(results || []);
                } else {
                    console.error("Nearby search failed: ", status);
                }
            })
        }

        try {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: newPlaceAddress }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (results && results.length > 0) {
                        const location = results[0].geometry.location;
                        setCenter({ lat: location.lat(), lng: location.lng() });
                        setSelectedLocation(location);
                    } else {
                        console.error('Nenhum resultado encontrado');
                    }
                } else {
                    console.error("Erro ao buscar o endereço!", status);
                }
            });
        } catch (error) {
            console.error('Erro ao buscar endereço: ', error);
        }
    };

    const handleSuggestionClick = (place: google.maps.places.PlaceResult) => {
        if (inputRef.current && place.geometry && place.geometry.location) {
            inputRef.current.value = place.name || '';
            setSuggestions([]);

            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();

            setNewLatitude(lat);
            setNewLongitude(lng);
            setMapCenter({ lat, lng });
            setSelectedLocation(new google.maps.LatLng(lat, lng));
        }
    }

    const formatCurrency = (value: string): string => {
        const numericValue = value.replace(/\D/g, '');

        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(parseFloat(numericValue) / 100);

        return formattedValue;
    };

    const formatCurrencyToString = (value: number): string => {
        if (typeof value !== "number" || isNaN(value)) {
            console.error("Valor recebido: ", value);
            throw new Error('Valor inválido. Deve ser um número.');
        }

        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        if (name === 'Value') {
            const numericValue = parseFloat(value.replace(/\D/g, '')) / 100;
            setNewValue(numericValue);
            setFormattedValue(formatCurrency(value));
        } else {
            return console.log("Erro ao setar valor");
        }
    };

    const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (e.target.files) {
            const files = Array.from(e.target.files);
            setImageFiles(files);

            const formDataToSend = new FormData();
            files.forEach((file) => {
                formDataToSend.append('files', file);
            });

            let updatedImageUrls: string[] = [...currentPlace.imageUrls];

            try {
                const uploadResponse = await axios.post('https://api-gaspar.turismourbano.com.br/api/places/upload-images', formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                updatedImageUrls = [...updatedImageUrls, ...uploadResponse.data.fileUrls];
            } catch (error) {
                console.error('Error uploading image:', error);
                return;
            }

            setCurrentPlace({
                ...currentPlace,
                imageUrls: updatedImageUrls
            });
            setImageUrls(updatedImageUrls);
            setCarouselKey(prevKey => prevKey + 1);
            setImageFiles([]);

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleSave = async () => {

        const checkLatitude = selectedLocation?.lat();
        const checkLongitude = selectedLocation?.lng();

        const requiredFields: Array<
            { name: string; value: string | number | string[] | undefined; message: string; isArray?: boolean }
        > = [
            {name: 'name', value: newPlaceName, message: 'O campo "Nome" está vazio!'},
            {name: 'description', value: newDescription, message: 'O campo "Descrição" está vazio!'},
            {name: 'categoryId', value: selectedCategoryId, message: 'Selecione uma categoria!'},
            {name: 'latitude', value: checkLatitude, message: 'Localização não selecionada!'},
            {name: 'longitude', value: checkLongitude, message: 'Localização não selecionada!'},
            {name: 'value', value: newValue, message: 'O campo "Valor" está vazio!'},
            {name: 'imageUrls', value: imageUrls, message: 'Nenhuma imagem inserida!', isArray: true}
        ];

        for (const field of requiredFields) {
            if (!field.value ||
                field.value === 0 ||
                field.value === '' ||
                field.value === null ||
                field.value === 'R$ 0,00' ||
                field.value === ' ') {
                toast.error(field.message);
                return;
            } else {
                if (field.isArray && Array.isArray(field.value)) {
                    if (field.value.length === 0) {
                        toast.error(field.message);
                        return;
                    }
                }
            }
        }

        const placeData = {
            id: currentPlace.id,
            name: newPlaceName,
            categoryId: selectedCategoryId,
            description: newDescription,
            latitude: selectedLocation?.lat(),
            longitude: selectedLocation?.lng(),
            isChecked: newIsChecked,
            imageUrls: imageUrls,
            value: newValue
        };

        try {
            await updatePlace(currentPlace.id, placeData);

            setCurrentPlace(placeData);

            setPlaces(prevPlaces =>
                prevPlaces.map(place =>
                    place.id === currentPlace.id ? {...place, ...placeData} : place
                )
            );

            setCarouselKey(prevKey => prevKey + 1);

            setShowConfirmation(true);

            setTimeout(() => {
                setShowConfirmation(false);
                window.location.reload();
            }, 1000);
        } catch (error) {
            console.error('Error updating place:', error);
            return;
        }

        setImageFiles([]);
        setImageUrls([]);
    };

    const handleDelete = async (id: number) => {
        await deletePlace(id);
        const updatedPlaces = await getPlaces();
        setPlaces(updatedPlaces);
    };

    const handleDeleteImage = async () => {
        if (currentPlace.imageUrls.length <= 1) {
            toast.error('Não é possível excluir a última imagem. Pelo menos uma imagem deve ser mantida.');
            return;
        }

        const imageUrl = currentPlace.imageUrls[currentImageIndex];
        try {
            const response = await axios.delete(`https://api-gaspar.turismourbano.com.br/api/places/delete-image`, {
                params: {imageUrl}
            });

            if (response.status === 200) {
                const updatedImageUrls = currentPlace.imageUrls.filter((url: string, index: number) => index !== currentImageIndex);
                setCurrentPlace({...currentPlace, imageUrls: updatedImageUrls});
                setImageUrls(updatedImageUrls);

                setCurrentImageIndex(prevIndex => (prevIndex >= updatedImageUrls.length ? updatedImageUrls.length - 1 : prevIndex));
                setCarouselKey(prevKey => prevKey + 1);

                if (updatedImageUrls.length === 0) {
                    setCurrentImageIndex(0)
                }

                const placeData = {
                    ...currentPlace,
                    imageUrls: updatedImageUrls
                }

                await updatePlace(currentPlace.id, placeData);

                setPlaces(prevPlaces =>
                    prevPlaces.map(place =>
                        place.id === currentPlace.id ? {...place, imageUrls: updatedImageUrls} : place
                    )
                );
            }
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearchAddress();
        }
    };
    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const filteredPlaces = places.filter(place =>
        place.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="place-container">
            <h2>LOCAIS CADASTRADOS</h2>
            <div className="search-container">
                <input
                    type="text"
                    id="place-search"
                    name="place-search"
                    placeholder="Pesquisar nome de locais..."
                    aria-label="Pesquisar nome de locais"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                />
            </div>
            <div className="place-category-list">
                {categories.map(category => (
                    <div key={category.id} className="place-category-item">
                        <h5>{category.name}</h5>
                        <ul className="place-component-list">
                            {filteredPlaces.filter(place => place.categoryId === category.id).map(place => (
                                <li className='me-5' key={place.id}>
                                    <ul className="me-3 fw-bold">
                                        <div className="text-start text-primary fs-8"
                                            style={{ width: '15rem', height: 'auto' }}>
                                            {place.name}
                                        </div>
                                    </ul>
                                    <div className="custom-scroll me-5 fs-8" style={{ maxWidth: '100%' }}>
                                        <div className="text-nowrap text-start">
                                            {place.description}
                                        </div>
                                    </div>
                                    <div className="place-component-buttons">
                                        <button className="place-edit-button" onClick={() => openModal(place)}>Editar
                                            <svg viewBox="0 0 512 512">
                                                <path
                                                    d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7
                                                    89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18
                                                    23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3
                                                    8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7
                                                    253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3
                                                    6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0
                                                    8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3
                                                    67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6
                                                    14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5
                                                    0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2
                                                    16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
                                            </svg>
                                        </button>
                                        <button className="place-delete-button" onClick={() => openDeleteConfirmationModal(place.id)}>
                                            <svg className="place-delete-button-top" viewBox="0 0 39 7" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <line y1="5" x2="39" y2="5" stroke="white" strokeWidth="4"></line>
                                                <line x1="12" y1="1.5" x2="26.0357" y2="1.5" stroke="white"
                                                      strokeWidth="3"></line>
                                            </svg>
                                            <svg className="place-delete-button-bottom" viewBox="0 0 33 39"
                                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <mask id="path-1-inside-1_8_19" fill="white">
                                                    <path
                                                        d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"
                                                    ></path>
                                                </mask>
                                                <path
                                                    d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                                                    fill="white"
                                                    mask="url(#path-1-inside-1_8_19)"
                                                ></path>
                                                <path d="M12 6L12 29" stroke="white" strokeWidth="4"></path>
                                                <path d="M21 6V29" stroke="white" strokeWidth="4"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <Modal
                className="place-modal"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Place Modal">
                <ToastContainer/>
                <h2>EDITAR LOCAL</h2>
                <div className="place-modal-content">
                    <div className="place-left-column">
                        <div className="place-name-input">
                            <label htmlFor="name">Local</label>
                            <input
                                type="text"
                                value={newPlaceName}
                                onChange={(e) => setNewPlaceName(e.target.value)}
                                placeholder="Nome do local..."
                                className="place-edit-name"
                            />
                        </div>
                        <div className="place-description-input">
                            <label htmlFor="description">Descrição</label>
                            <textarea
                                value={newDescription}
                                onChange={(e) => {
                                    setNewDescription(e.target.value);
                                    setCharCount(e.target.value.length);
                                }}
                                placeholder="Descrição do local..."
                                className="place-edit-description"
                                maxLength={charLimit}
                            />
                            <div>
                                <small>{charCount}/{charLimit} caracteres.</small>
                                {charCount === charLimit && (
                                    <p className="text-danger">Você atingiu o limite de {charLimit} caracteres.</p>
                                )}
                            </div>
                        </div>
                        <div className="place-value-input">
                            <label htmlFor="value">Valor de Contrato</label>
                            <input
                                type="text"
                                id="Value"
                                name="Value"
                                placeholder="R$ 0,00"
                                value={formattedValue}
                                onChange={handleChange}
                                className="place-edit-value"
                            />
                        </div>
                        <div className="place-category-input">
                            <label htmlFor="value">Categoria</label>
                            <select
                                value={selectedCategoryId || ''}
                                onChange={(e) => setSelectedCategoryId(Number(e.target.value))}
                                className="place-select"
                            >
                                <option value="" disabled>Categoria</option>
                                {categories.map(category => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="place-center-column">
                        <div className="place-mapbox">
                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                zoom={15}
                                center={mapCenter}
                                onClick={handleMapClick}
                            >
                                {selectedLocation && <Marker position={selectedLocation.toJSON()}/>}
                            </GoogleMap>
                        </div>
                        <div className="place-search-input">
                            <input
                                ref={inputRef}
                                type="text"
                                value={newPlaceAddress}
                                onChange={(e) => setNewPlaceAddress(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="Pesquisar endereço..."
                            />
                            {suggestions.length > 0 && (
                                <ul ref={suggestionsRef} className="place-autocomplete-dropdown">
                                    {suggestions.map((suggestion, index) => (
                                        <li
                                            key={index}
                                            onClick={() => handleSuggestionClick(suggestion)}
                                            style={{cursor: 'pointer', padding: '8px'}}
                                        >
                                            <i className="material-icons">place</i>
                                            {suggestion.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            <button onClick={handleSearchAddress}>Pesquisar</button>
                        </div>
                    </div>
                    <div className="place-right-column">
                        {currentPlace.imageUrls && currentPlace.imageUrls.length > 0 && (
                            <div className="place-carousel-container">
                                <Carousel
                                    showThumbs={false}
                                    onChange={(index) => setCurrentImageIndex(index)}
                                    className="place-carousel"
                                    selectedItem={currentImageIndex}
                                >
                                    {currentPlace.imageUrls.map((url: string, index: number) => (
                                        <div key={index} className="slide">
                                            <img src={`https://api-gaspar.turismourbano.com.br/api/places/image/${url}`}
                                                 alt={`Image ${index + 1}`}
                                                 width="100%"/>
                                        </div>
                                    ))}
                                </Carousel>
                                <div className="place-manage-image-container">
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        multiple
                                        onChange={handleImageChange}
                                        style={{display: 'none'}}
                                    />
                                    <button
                                        className="place-open-file"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            fileInputRef.current?.click()
                                        }}>
                                        <span className="place-file-wrapper">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 71 67">
                                                <path
                                                    stroke-width="5"
                                                    stroke="black"
                                                    d="M41.7322 11.7678L42.4645 12.5H43.5H68.5V64.5H2.5V2.5H32.4645L41.7322 11.7678Z"
                                                ></path>
                                            </svg><span className="place-file-front"></span>
                                        </span>
                                        Carregar Imagens
                                    </button>
                                    <button className="place-delete-image-button" onClick={handleDeleteImage}>
                                        Excluir Imagem
                                    </button>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
                <div className="place-modal-footer">
                    <button className="place-save-button" onClick={handleSave}>
                        Salvar
                        <svg fill="white" viewBox="0 0 448 512" height="1em" className="arrow">
                            <path
                                d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5
                                32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4
                                393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                        </svg>
                    </button>
                    <button className="place-cancel-button" onClick={closeModal}>
                        Cancelar
                    </button>
                </div>
                {showConfirmation && (
                    <div className="place-confirmation-box">
                        Alterações salvas com sucesso!
                    </div>
                )}
            </Modal>
            <Modal
            className="delete-modal"
                isOpen={deleteConfirmationIsOpen}
                onRequestClose={closeDeleteConfirmationModal}
                contentLabel="Delete Confirmation Modal"
            >
                <div className='delete-confirmation-box'>
                    <div>
                        <h2>Confirmação de Exclusão</h2>
                        <p>Você tem certeza que deseja excluir este local?</p>
                    </div>
                    <div>
                        <button className='ConfirmDeleteButton' onClick={confirmDeletePlace}>Confirmar</button>
                        <button className='CancelDeleteButton' onClick={closeDeleteConfirmationModal}>Cancelar</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default PlaceComponent;
