import React, {useState, useEffect} from "react";
import {FaTimes, FaChevronUp} from 'react-icons/fa';

const PopUpScreen = () => {

    const [isOpen, setIsOpen] = useState(() => {
        const hasShownPopUp = localStorage.getItem('popUpShown');
        return !hasShownPopUp;
    });

    useEffect(() => {
        if (isOpen) {
            localStorage.setItem('popUpShown', 'true');
        }
    }, [isOpen]);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`popup-container ${isOpen ? 'open' : 'closed'}`}>
            {isOpen && (
                <>
                    <button className="popup-close-btn" onClick={togglePopup}>
                        <FaTimes/>
                    </button>
                    <div className="popup-content">
                        <div className="popup-section">
                            <p className="fw-bolder fs-6">
                                Navegue por nosso guia para conhecer as maravilhas de Gaspar e planejar sua viagem com
                                todas as informações que você precisa.
                                Além das descrições dos pontos turísticos, oferecemos recursos visuais, mapas
                                interativos, e recomendações de estabelecimentos locais
                                para tornar sua estadia inesquecível. Pronto para começar sua jornada por Gaspar?
                                Explore os pontos turísticos e prepare-se para se encantar!
                            </p>
                        </div>
                        <div className="popup-section">
                            <p className="fw-bolder fs-6">
                                O Turismo Urbano Gaspar é o seu companheiro ideal para explorar os pontos turísticos
                                mais encantadores da cidade. Nossa iniciativa
                                mapeou e marcou os principais locais de interesse com totens informativos, cada um
                                equipado com um QR code que traz você a esse site.
                                Aqui, você encontrará uma descrição detalhada de cada atração, incluindo sua história,
                                importância cultural, e dicas para aproveitar
                                ao máximo sua visita.
                            </p>
                        </div>
                    </div>
                </>
            )}
            {!isOpen && (
                <button className="popup-toggle-btn minimized" onClick={togglePopup}>
                    <FaChevronUp/>
                </button>
            )}
        </div>
    );
}

export default PopUpScreen;
