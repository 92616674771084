import React, {useEffect, useState} from 'react';
import {getAds} from '../../../../api/api';
import './adBanner.css';

const AdBanner: React.FC = () => {
    const [ads, setAds] = useState<any[]>([]);

    const fetchAds = async () => {
        try {
            const allAds = await getAds();
            let selectedAds: any[] = [];

            if (allAds.length >= 3) {
                const randomIndexes: number[] = [];
                while (randomIndexes.length < 2) {
                    const randomIndex = Math.floor(Math.random() * allAds.length);
                    if (!randomIndexes.includes(randomIndex)) {
                        randomIndexes.push(randomIndex);
                    }
                }
                selectedAds = randomIndexes.map(index => allAds[index]);
            } else {
                selectedAds = allAds;
            }

            setAds(selectedAds);
        } catch (error) {
            console.error('Erro ao buscar os anúncios:', error);
        }
    };

    useEffect(() => {
        fetchAds();
    }, []);

    return (
        <div className={`ad-banner-container-ad ${ads.length === 1 ? 'single-ad' : ''}`}>
            {ads.map((ad) => (
                <div key={ad.id} className="ad-banner-ad">
                    <a href={ad.url}>
                        <img src={`https://api-gaspar.turismourbano.com.br/api/ads/image/${ad.imageUrls?.[0]}`} alt={"Anúncio da " + ad.name} className="ad-banner-image-ad"/>
                    </a>
                </div>
            ))}
        </div>
    );
}

export default AdBanner;
