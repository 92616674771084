import React, {useState, useEffect, useRef} from 'react';
import {createAd} from '../api/api';
import './AdComponent.css';
import axios from 'axios';
import {toast, ToastContainer} from "react-toastify";

const AdForm: React.FC = () => {

    const [charCount, setCharCount] = useState(0);
    const charLimit = 250;
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        url: '',
        imageUrls: [],
    });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [imageFiles, setImageFiles] = useState<File[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {

    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;
        setCharCount(value.length);
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (e.target.files) {
            const filesArray = Array.from(e.target.files);
            setImageFiles(filesArray);

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setIsSubmitting(true);

        let imageUrls: string[] = [];

        if (imageFiles.length > 0) {
            const formDataToSend = new FormData();

            imageFiles.forEach((file) => {
                formDataToSend.append('files', file);
            });

            try {
                const uploadResponse = await axios.post('https://api-gaspar.turismourbano.com.br/api/ads/upload-images-ads', formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                imageUrls = uploadResponse.data.fileUrls;

                setImageFiles([]);
            } catch (error) {
                console.error('Error uploading images:', error);
                setIsSubmitting(false);
                return;
            }
        }

        const requiredFields: Array<
            { name: string; value: string | string[] | undefined; message: string; isArray?: boolean }
        > = [
            {name: 'name', value: formData.name, message: 'O campo "Nome" está vazio!'},
            {name: 'description', value: formData.description, message: 'O campo "Descrição" está vazio!'},
            {name: 'url', value: formData.url, message: 'O campo "Url" está vazio!'},
            {name: 'imageUrls', value: imageUrls, message: 'Nenhuma imagem inserida!', isArray: true}
        ];

        for (const field of requiredFields) {
            if (!field.value ||
                field.value === '' ||
                field.value === null ||
                field.value === ' ') {
                toast.error(field.message);
                setIsSubmitting(false);
                return;
            } else {
                if (field.isArray && Array.isArray(field.value)) {
                    if (field.value.length === 0) {
                        toast.error(field.message);
                        setIsSubmitting(false);
                        return;
                    }
                }
            }
        }

        try {
            await createAd({
                ...formData,
                imageUrls: imageUrls
            });

            setShowConfirmation(true);

            setTimeout(() => {
                setShowConfirmation(false);
                window.location.reload();
            }, 1500);

            setFormData({
                name: '',
                description: '',
                url: '',
                imageUrls: [],
            });

        } catch (error) {
            console.error('Error creating ad:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="ad-form-section">
            <ToastContainer/>
            <div className="ad-empty-container">
                <div className="ad-empty-banner">
                    <h2>TEMPLATE DE ANÚNCIO</h2>
                    <p>Largura: 592px Altura: 160px</p>
                </div>
            </div>
            {isSubmitting && (
                <div className="place-confirmation-box">
                    Carregando...
                </div>
            )}
            {showConfirmation && (
                <div className="place-confirmation-box">
                    Anúncio adicionado com sucesso!
                </div>
            )}
            <div className="ad-formbox">
                <form onSubmit={handleSubmit} className="ad-form-container">
                    <div className="ad-form-group">
                        <label htmlFor="name">Nome</label>
                        <div className="ad-name-input mb-2">
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder='Digite...'
                                value={formData.name}
                                onChange={handleChange}
                                style={{width: '100%'}}
                            />
                        </div>
                    </div>
                    <div className="ad-form-group">
                        <label htmlFor="description">Descrição</label>
                        <div className="ad-description-input">
                        <textarea
                            id="description"
                            name="description"
                            placeholder='Digite...'
                            value={formData.description}
                            onChange={handleChange}
                            maxLength={charLimit}
                            rows={4}
                            style={{width: '100%'}}
                        />
                            <div className='fw-normal'>{charLimit - charCount} caracteres restantes</div>
                        </div>
                    </div>
                    <div className="ad-form-group">
                        <label htmlFor="url">URL</label>
                        <div className='ad-url-input'>
                            <input
                                type="url"
                                id="url"
                                name="url"
                                placeholder='Insira a URL de redirecionamento...'
                                value={formData.url}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="ad-form-group">
                        <label htmlFor="image">Imagens:</label>
                        <input
                            ref={fileInputRef}
                            type="file"
                            multiple
                            onChange={handleImageChange}
                            style={{display: 'none'}}
                        />
                        <button
                            className="ad-open-file"
                            onClick={(e) => {
                                e.preventDefault();
                                fileInputRef.current?.click()
                            }}>
                            <span className="ad-file-wrapper">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 71 67">
                                    <path
                                        stroke-width="5"
                                        stroke="black"
                                        d="M41.7322 11.7678L42.4645 12.5H43.5H68.5V64.5H2.5V2.5H32.4645L41.7322 11.7678Z"
                                    ></path>
                                </svg><span className="ad-file-front"></span>
                            </span>
                            Carregar Imagens
                        </button>
                    </div>
                    <button className="ad-register-button" disabled={isSubmitting}>
                        Cadastrar
                        <svg fill="white" viewBox="0 0 448 512" height="1em" className="arrow">
                            <path
                                d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5
                                32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4
                                393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                        </svg>
                    </button>
                </form>
            </div>
        </div>
    )
};

export default AdForm;
